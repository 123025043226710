import tracking, { TrackingCardPosition } from 'src/tracking'
import { CardContentType } from 'src/components/molecules/ContentCard'

import { ArticleUnion } from '../../graphql/generated/api-graphql'
import Router from '../../routes/Router'
import { wediaImagesTransform } from '../Common/wedia'
import { HeroProps } from '../../components/molecules/Hero'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { resolveArticleRoute } from '../article/resolveArticleRoute'

export const transformArticleBigCard = (
  t: any,
  data?: ArticleUnion,
  blockName?: string,
  isHP = false
): HeroProps => {
  const article: ArticleUnion = data as ArticleUnion

  return {
    title: isHP ? data?.title_homepage ?? data?.title : data?.title ?? '',
    ...((data?.slug && data.__typename === 'CulinaryArticle') ||
    (data?.slug && data.__typename === 'Article') ||
    (data && 'type' in data && data?.type)
      ? {
          linkProps: {
            href: Router.getRouteUrl(resolveArticleRoute(data), {
              slug: data?.slug,
            }),
            onClick: () =>
              tracking.cards.block(
                blockName,
                CardContentType.Article,
                data.title,
                TrackingCardPosition.HERO
              ),
          },
          isFullLink: true,
          buttonLabel: t('articleCard_buttonLabel'),
        }
      : {}),
    ...(article?.brand?.pictureLogo && {
      brandLogoProps: {
        alt: article?.brand?.pictureLogo?.alt ?? ' ',
        maxWidth: 80,
        width: 80,
        height: 80,
        withHD: true,
        withLazyLoading: true,
        images: wediaImagesTransform(article.brand.pictureLogo),
      },
    }),
    imageProps: {
      maxWidth: 1280,
      width: 1200,
      height: 480,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.mainImage),
      alt: data?.title ?? '',
    },
    mobileImageProps: {
      maxWidth: 370,
      width: 370,
      height: 200,
      withHD: true,
      withLazyLoading: true,
      images: wediaImagesTransform(data?.mainImage),
      alt: data?.title ?? '',
    },
    topTags: [{ label: t('tag_article'), variant: TagVariant.article }],
    bottomTags: [
      // drop all the tags in order then slice the first 2
      ...(data?.positiveFood
        ? [
            {
              label: t('positive_food'),
              variant: TagVariant.positive,
            },
          ]
        : []),
      ...(article &&
      'tagsConsumptionTime' in article &&
      article?.tagsConsumptionTime?.[0]
        ? [
            {
              label: article?.tagsConsumptionTime?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagsTheme' in article && article?.tagsTheme?.[0]
        ? [
            {
              label: article?.tagsTheme?.[0]?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagTheme' in article && article?.tagTheme
        ? [
            {
              label: article?.tagTheme?.name,
              variant: TagVariant.meal,
            },
          ]
        : []),
      ...(article && 'tagHealthTheme' in article && article?.tagHealthTheme
        ? [
            {
              label: article?.tagHealthTheme?.name,
              variant: TagVariant.diet,
            },
          ]
        : []),
      ...(article?.brand
        ? [{ label: t('tag_brand'), variant: TagVariant.brand }]
        : []),
    ].slice(0, 2),
  }
}
